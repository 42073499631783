import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { ChevronLeft } from "react-feather"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideBar from "../components/SideBar"
import SideArticleList from "../components/SideArticleList"
import "./blogTemplate.css"

export default function BlogTemplate({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html, id } = markdownRemark
  const initialProtection = frontmatter.security
  const [passwordProtected, updateProtection] = useState(initialProtection)
  const [password, updatePassword] = useState("")
  const [formvalidation, setFormValidation] = useState(null)

  const handleSubmit = e => {
    e.preventDefault()
    if (password === "d3@articles") {
      updateProtection(null)
      setFormValidation(null)
    } else {
      setFormValidation("invalid")
    }
  }

  return (
    <div className="wrapper">
      <Layout>
        <SEO title={frontmatter.title} />
        <SideBar>
          <Link to={"/"}>
            {/* <ChevronLeft style={{ marginTop: "8px" }} /> */}
            <span>Back Home</span>
          </Link>

          <h3
            className="read-more"
            style={{ marginBottom: `1rem`, marginTop: "2rem" }}
          >
            <div className="eyes-symbol" role="img" aria-label="read">
              <div className="eye">
                <div className="eye-ball">
                  <div className="eye-light" />
                </div>
              </div>
              <div className="eye">
                <div className="eye-ball">
                  <div className="eye-light" />
                </div>
              </div>
            </div>
            Read More
          </h3>

          <SideArticleList id={id} />
        </SideBar>
        <div className="blog-post-container">
          <div className="blog-post">
            {/* <p className="blog-date" style={{ opacity: 0.5 }}>
              {frontmatter.date}
            </p> */}
            <h1 className="blog-title">{frontmatter.title}</h1>
            {passwordProtected && passwordProtected === "protected" ? (
              <div className="password-protection">
                <div className="protection-message">
                  Sorry, this article is not publicly available. Please request
                  a password for access.
                </div>
                <div>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="password"
                      value={password}
                      name="password"
                      onChange={e => updatePassword(e.target.value)}
                    />
                    <input
                      className="submit-button"
                      type="submit"
                      value="Submit"
                    />
                  </form>
                </div>
                {formvalidation === "invalid" && (
                  <div className="Alert">The password is incorrect</div>
                )}
              </div>
            ) : (
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            )}
          </div>
        </div>
      </Layout>
      <div className="cover" style={{ backgroundColor: "var(--bg)" }} />
    </div>
  )
}
export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        security
      }
    }
  }
`
